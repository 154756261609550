import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '@components/Layout'
import Seo from '@components/Seo'
import Hero from '@components/PageComponents/Hero'
import InformationSection from '@components/PageComponents/InformationSection'
import AccordionSection from '@components/PageComponents/AccordionSection'
import {
  HomePageQuery,
  ContentfulHero,
  ContentfulInformationBlock,
  ContentfulAccordion,
} from 'typings/contentful-queries'

interface IndexProps {
  data: HomePageQuery
}

const IndexPage: React.FC<IndexProps> = ({ data }) => {
  const { metaTitle, metaDescription, heroSection, informationSection, accordionSection } =
    data.contentfulHomePage ?? {}
  return (
    <Layout lang={'en-US'}>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription?.metaDescription ?? ''} />
      </Helmet>
      <Seo
        ogUrl="https://www.icbcfairnessofficer.com"
        metaTitle={metaTitle ?? ''}
        metaDescription={metaDescription?.metaDescription ?? ''}
        metaImage={''}
        // metaImage={metaImage?.image.file.url ?? ''}
      />
      <main>
        <Hero data={heroSection as ContentfulHero} />
        <InformationSection data={informationSection as ContentfulInformationBlock[]} />
        <AccordionSection data={accordionSection as ContentfulAccordion[]} />
      </main>
    </Layout>
  )
}

export default IndexPage

// NOTE:
// Codegen will cause issues when attempting to generate types for '...GatsbyContentfulFluid_withWebP'. In that case, replace those lines with the following lines and regenerate the types:
//   base64
//   aspectRatio
//   src
//   srcSet
//   srcWebp
//   srcSetWebp
//   sizes

export const pageQuery = graphql`
  query HomePage {
    contentfulHomePage(contentful_id: { eq: "AN2xyj5Wi92wHbmp4ljhj" }) {
      metaTitle
      metaDescription {
        metaDescription
      }
      heroSection {
        heading
        image {
          description
          fluid(maxWidth: 329, quality: 100) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        body {
          raw
        }
        signatureImage {
          description
          fluid(maxWidth: 208, quality: 100) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        signatureText
      }
      informationSection {
        anchor
        heading
        body {
          raw
          references {
            ... on ContentfulButton {
              destination
              displayText
              name
              contentfulid
              contentful_id
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
            }
          }
        }
      }
      accordionSection {
        title
        anchor
        body {
          raw
        }
      }
    }
  }
`
