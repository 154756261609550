import styled from '@emotion/styled'
import Image from 'gatsby-image'
import { under, over } from '@theme/media'
import r from '@utils/pxToRem'

const HeroContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${r`24px 16px`};
  background: ${({ theme }) => theme.colors.lightGrey};

  ${over.tablet`
    padding: ${r`48px 16px`};
  `}

  ${over.laptop`
    padding: ${r`64px 16px`};
  `}
`

const HeroHeader = styled.h1`
  text-align: center;
  margin: ${r`0 24px`};
  color: ${({ theme }) => theme.colors.darkBlue};
  font-size: ${({ theme }) => theme.fontSizes.heading[2]};
  font-weight: ${({ theme }) => theme.fontWeights.heading};
  line-height: ${({ theme }) => theme.lineHeights.heading[2]};

  ${over.tablet`
    margin: ${r`0 16px`};
    width: 75vw;
  `}

  ${over.laptop`
    margin: ${r`0 16px`};
    width: 54ch;
  `}

  ${({ theme }) =>
    over.tablet`
      font-size: ${theme.fontSizes.heading[1]};
      line-height: ${theme.lineHeights.heading[1]};
    `};

  ${({ theme }) =>
    over.laptop`
      font-size: ${theme.fontSizes.heading[0]};
      line-height: ${theme.lineHeights.heading[0]};
    `};
`

const HeroLetter = styled.div`
  display: grid;
  grid-template:
    'image image image' 1fr
    'body  body  body ' auto
    '..  sign-image ..' auto
    '... sign-text ...' auto / 1fr minmax(88px, 208px) 1fr;
  margin: ${r`32px 32px`};
  text-align: center;

  ${over.tablet`
    grid-template:
      'image body body body' 1fr
      'image .... sign-image ..' auto
      'image .... sign-text  ..' auto / 275px 1fr 174px 56px;
    width: 660px;
    margin: ${r`32px 16px`};
    text-align: left;
  `}

  ${over.laptop`
    grid-template:
      'image body body body' 1fr
      'image .... sign-image ..' auto
      'image .... sign-text  ..' auto / 329px 1fr 208px 56px;
    width: 862px;
    margin: ${r`48px 16px`};
  `}
`

const HeroImage = styled(Image)`
  grid-area: image;

  ${under.tablet`
    margin: 0 26% ${r`16px`};
  `}

  ${under.thone`
    margin: 0 17% ${r`16px`};
  `}
`

const HeroBody = styled.div`
  grid-area: body;
  margin: ${r`20px 0`};

  ${over.tablet`
    margin: ${r`16px 20px 0`};
  `}

  ${over.laptop`
    margin: ${r`44px 44px 0`};
  `}

  p {
    line-height: ${({ theme }) => theme.lineHeights.body[0]};
    margin-bottom: ${r`8px`};
  }
`

const SignatureImage = styled(Image)`
  grid-area: sign-image;
`

const SignatureText = styled.span`
  grid-area: sign-text;
  display: inline-flex;
  justify-content: center;
`

export { HeroContainer, HeroHeader, HeroLetter, HeroImage, HeroBody, SignatureImage, SignatureText }
