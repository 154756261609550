import * as React from 'react'
import { FluidObject } from 'gatsby-image'
import { ContentfulHero } from 'typings/contentful-queries'
import RichText from '@components/RichText'
import {
  HeroContainer,
  HeroHeader,
  HeroLetter,
  HeroImage,
  HeroBody,
  SignatureImage,
  SignatureText,
} from './Styles'

export interface HeroProps {
  data: ContentfulHero
}

const Hero: React.FC<HeroProps> = ({ data }) => {
  const { heading, image, body, signatureImage, signatureText } = data
  return (
    <HeroContainer>
      <HeroHeader>{heading}</HeroHeader>
      <HeroLetter>
        <HeroImage
          fluid={image?.fluid as FluidObject}
          alt={image?.description ?? ''}
          loading="eager"
        />
        <HeroBody>
          <RichText data={body} />
        </HeroBody>
        <SignatureImage
          fluid={signatureImage?.fluid as FluidObject}
          alt={signatureImage?.description ?? ''}
        />
        <SignatureText>{signatureText}</SignatureText>
      </HeroLetter>
    </HeroContainer>
  )
}

export default Hero
