import styled from '@emotion/styled'
import { over } from '@theme/media'
import r from '@utils/pxToRem'

const InformationBlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${r`24px 16px`};
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};

  &:last-of-type {
    border-bottom: none;
  }

  .rich-text {
    flex: 3;

    #submit-request-cta-button {
      display: block;
      margin-top: ${r`32px`};
      text-align: center;

      ${over.phablet`
        display: inline-block;
      `}
    }

    h3 {
      margin-top: ${r`48px`};

      &:first-of-type {
        margin-top: 0;
      }
    }
  }

  ${over.tablet`
    padding: ${r`32px 16px`};
  `}

  ${over.laptop`
    flex-direction: row;
    padding: ${r`64px 16px`};
  `}
`

const InformationBlockHeader = styled.h2`
  flex: 2;
  font-size: ${({ theme }) => theme.fontSizes.heading[1]};
  font-weight: ${({ theme }) => theme.fontWeights.heading};
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.darkBlue};
  margin-bottom: ${r`12px`};

  ${over.laptop`
    margin-bottom: 0;
  `}

  ${({ theme }) =>
    over.tablet`
      font-size: ${theme.fontSizes.heading[0]};
    `};
`

export { InformationBlockContainer, InformationBlockHeader }
