import React from 'react'
import Helmet from 'react-helmet'

export interface SeoProps {
  ogUrl: string
  metaTitle: string
  metaDescription: string
  metaImage: string
}

const Seo: React.FC<SeoProps> = ({ ogUrl, metaTitle, metaDescription, metaImage }) => {
  return (
    <Helmet>
      <meta property="og:type" content="website" />
      <meta property="og:url" content={ogUrl} />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={`https:${metaImage}`} />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={ogUrl} />
      <meta property="twitter:title" content={metaTitle} />
      <meta property="twitter:description" content={metaDescription} />
      <meta property="twitter:image" content={`https:${metaImage}`}></meta>
    </Helmet>
  )
}

export default Seo
