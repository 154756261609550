import styled from '@emotion/styled'
import { css } from '@emotion/react'
import r from '@utils/pxToRem'
import theme from '@theme/theme'

const commonStyles = css`
  display: inline-block;
  font-family: ${theme.fonts.montserrat};
  font-size: ${theme.fontSizes.button[1]};
  font-weight: ${theme.fontWeights.button};
  line-height: ${theme.lineHeights.button[1]};
  text-decoration: none;
  color: ${theme.colors.white};
  background-color: ${theme.colors.darkBlue};
  padding: ${r`12px 38px`};
  border-radius: 100px;
  transition: all 0.15s;

  &:visited {
    color: ${theme.colors.white};
  }

  &:hover {
    transform: scale(1.02);
  }
`

const StyledLink = styled.a`
  ${commonStyles}
`

const StyledButton = styled.button`
  ${commonStyles}
`

export { StyledLink, StyledButton }
