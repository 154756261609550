import * as React from 'react'
import { ContentfulInformationBlock } from 'typings/contentful-queries'
import InformationBlock from '@components/InformationBlock/informationBlock'
import InformationSectionContainer from './Styles'

export interface InformationSectionProps {
  data: ContentfulInformationBlock[]
}

const InformationSection: React.FC<InformationSectionProps> = ({ data: informationBlocks }) => {
  return (
    <InformationSectionContainer>
      {informationBlocks.map(informationBlock => (
        <InformationBlock
          key={informationBlock.anchor ?? ''}
          heading={informationBlock.heading ?? ''}
          anchor={informationBlock.anchor ?? ''}
          body={informationBlock.body ?? ''}
        />
      ))}
    </InformationSectionContainer>
  )
}

export default InformationSection
