import React, { useEffect } from 'react'
import { ContentfulAccordionBody } from 'typings/contentful-queries'
import { AccordionItem } from '@reach/accordion'
import RichText from '@components/RichText'
import Chevron from '@components/Chevron'

import { AccordionHeaderContainer, AccordionHeader, AccordionPanel } from './Styles'

export interface AccordionProps {
  accordionIndex: number
  title: string
  anchor: string
  body: string | ContentfulAccordionBody
  toggleItem: (toggledIndex: number) => void
}

const Accordion: React.FC<AccordionProps> = ({
  title,
  anchor,
  body,
  accordionIndex,
  toggleItem,
}) => {
  const isBrowser = typeof window !== 'undefined'
  const isCorresspondingHash = isBrowser && window.location.hash.endsWith(anchor)
  const appendAnchor = () => {
    if (window.location.hash !== `#${anchor}`) {
      if (window.history.pushState) {
        window.history.pushState(null, '', `#${anchor}`)
      } else {
        window.location.hash = `#${anchor}`
      }
    }
  }

  useEffect(() => {
    if (isCorresspondingHash) toggleItem(accordionIndex)
  }, [])
  return (
    <AccordionItem>
      <AccordionHeaderContainer id={anchor} onClick={appendAnchor} onKeyDown={appendAnchor}>
        <AccordionHeader className={`${anchor}-button`}>
          <span id={`${anchor}-title`}>{title}</span>
          <Chevron id={`${anchor}-icon`} size={30} />
        </AccordionHeader>
      </AccordionHeaderContainer>
      <AccordionPanel>
        <RichText data={body} />
      </AccordionPanel>
    </AccordionItem>
  )
}

export default Accordion
