import React from 'react'
import { ContentfulInformationBlockBody } from 'typings/contentful-queries'
import RichText from '@components/RichText'
import { InformationBlockContainer, InformationBlockHeader } from './Styles'

export interface InformationBlockProps {
  heading: string
  anchor: string
  body: string | ContentfulInformationBlockBody
}

const InformationBlock: React.FC<InformationBlockProps> = ({ heading, anchor, body }) => (
  <InformationBlockContainer id={anchor}>
    <InformationBlockHeader>{heading}</InformationBlockHeader>
    <RichText data={body} />
  </InformationBlockContainer>
)

export default InformationBlock
