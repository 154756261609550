/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-restricted-globals */
import React from 'react'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import Button from '@components/Button'

export interface RichTextProps {
  data: any
  options?: Options
}

export interface HyperlinkNode {
  content: { value?: string }[]
  data: { uri: string }
}

const defaultOptions = {
  renderNode: {
    [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
      const { destination, displayText, contentfulid } = node.data.target
      return <Button href={destination} id={contentfulid} label={displayText} />
    },

    [INLINES.HYPERLINK]: (node: any) => {
      return (
        <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
          {node.content[0].value}
        </a>
      )
    },
  },
}

const RichText = ({ data, options = defaultOptions }: RichTextProps) => {
  return <div className="rich-text">{renderRichText(data, options)}</div>
}

export default RichText
