import styled from '@emotion/styled'
import {
  AccordionButton as ReachAccordionButton,
  AccordionPanel as ReachAccordionPanel,
} from '@reach/accordion'
import { over } from '@theme/media'
import r from '@utils/pxToRem'

const AccordionHeaderContainer = styled.header`
  display: flex;
`

const AccordionHeader = styled(ReachAccordionButton)`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: space-between;
  font-family: ${({ theme }) => theme.fonts.montserrat};
  font-size: ${({ theme }) => theme.fontSizes.accordionHeading[1]};
  font-weight: ${({ theme }) => theme.fontWeights.accordionHeading};
  line-height: ${({ theme }) => theme.lineHeights.accordionHeading};
  color: ${({ theme }) => theme.colors.textBlack};
  background-color: ${({ theme }) => theme.colors.white};
  border: none;
  cursor: pointer;
  padding: ${r`16px 0`};

  &[data-state='open'] {
    .chevron-icon svg path {
      transform: rotate(0);
    }
  }

  ${over.tablet`
    padding: ${r`20px 0`};
  `}

  ${({ theme }) =>
    over.tablet`
      font-size: ${theme.fontSizes.accordionHeading[0]};
    `};
`

const AccordionPanel = styled(ReachAccordionPanel)`
  padding: ${r`16px 0`};
  ${over.laptop`
    max-width: 688px;
    margin: 0 auto;
    padding: 0 0 ${r`20px`} 180px;
  `}
`

export { AccordionHeaderContainer, AccordionHeader, AccordionPanel }
