import styled from '@emotion/styled'
import { over } from '@theme/media'
import r from '@utils/pxToRem'

const AccordionSectionContainer = styled.section`
  margin: 0 auto;
  max-width: ${({ theme }) => theme.elementSizes.maxPageWidth}px;
  padding: ${r`8px 16px`};
  background: ${({ theme }) => theme.colors.white};

  ${over.tablet`
    padding: ${r`16px 16px`};
  `}

  ${over.laptop`
    padding: ${r`24px 16px`};
  `}

  [data-reach-accordion-item] {
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
    padding: ${r`0 16px`};

    ${over.tablet`
      padding: ${r`0 20px`};
    `}
    &:first-of-type {
      border-top: 1px solid ${({ theme }) => theme.colors.lightGrey};
    }
  }
`

export default AccordionSectionContainer
