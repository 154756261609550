import React, { useState } from 'react'
import { Accordion as ReachAccordion } from '@reach/accordion'
import { ContentfulAccordion } from 'typings/contentful-queries'
import Accordion from '@components/Accordion'
import '@reach/accordion/styles.css'
import AccordionSectionContainer from './Styles'

export interface AccordionSectionProps {
  data: ContentfulAccordion[]
}

const AccordionSection: React.FC<AccordionSectionProps> = ({ data: Accordions }) => {
  const [indices, setIndices] = useState([-1])
  const toggleItem = (toggledIndex: number) => {
    if (indices.includes(toggledIndex)) {
      setIndices(indices.filter(currentIndex => currentIndex !== toggledIndex))
    } else {
      setIndices([...indices, toggledIndex].sort())
    }
  }

  return (
    <AccordionSectionContainer>
      <ReachAccordion index={indices} onChange={toggleItem}>
        {Accordions.map((accordion, i) => (
          <Accordion
            accordionIndex={i}
            key={accordion.anchor ?? ''}
            title={accordion.title ?? ''}
            anchor={accordion.anchor ?? ''}
            body={accordion.body ?? ''}
            toggleItem={toggleItem}
          />
        ))}
      </ReachAccordion>
    </AccordionSectionContainer>
  )
}

export default AccordionSection
